import { RightOutlined } from "@ant-design/icons";
import { Button, Col, List, Row, Tag, Typography } from "antd";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useLayoutEffect } from "react";

import { Box } from "~/src/components/box";
import { Container } from "~/src/components/container";
import { Flex } from "~/src/components/flex";
import {
  getFormattedDepartments,
  getPublicUrl,
  isAcceptingApplications,
  Job,
} from "~/src/lib/api/jobs";
import { useSearchParams } from "~/src/lib/use-search-params";

type AvailabilityTagProps = {
  job: Job;
};

const AvailabilityTag = ({ job }: AvailabilityTagProps) => {
  const { t } = useTranslation("models/job");
  const isAvailable = isAcceptingApplications(job);

  if (isAvailable) {
    return (
      <Tag color="success">
        {t("models/job:availability-tag.accepting-applications")}
      </Tag>
    );
  }

  return <Tag>{t("models/job:availability-tag.closed")}</Tag>;
};

type ListItemProps = {
  job: Job;
};

const ListItem = ({ job }: ListItemProps) => {
  const { t } = useTranslation("models/job");
  const { pageSlug } = useSearchParams({ pageSlug: "string" });
  const { name, departments, laborRegime, modal, slug } = job;

  const jobUrl = getPublicUrl({ slug }, { slug: pageSlug });
  const formattedDepartments = getFormattedDepartments(departments);

  useLayoutEffect(() => {
    const elementId = window.location.hash.slice(1);
    if (!elementId) {
      return;
    }

    const anchoredSection = document.getElementById(elementId);
    if (!anchoredSection) {
      return;
    }

    anchoredSection.scrollIntoView({ behavior: "smooth" });
  }, []);

  const extraContent = (
    <Flex align="center" style={{ height: "100%" }}>
      <Button type="link" icon={<RightOutlined />} size="small" />
    </Flex>
  );

  return (
    <List.Item extra={extraContent}>
      <Box paddingVertical="xs">
        <Link href={jobUrl}>
          <Row align="middle" gutter={[16, 8]}>
            <Col md={12} sm={24} xs={24}>
              <List.Item.Meta
                title={<Typography.Title level={5}>{name}</Typography.Title>}
                description={
                  <Typography.Text ellipsis>
                    {formattedDepartments}
                  </Typography.Text>
                }
                style={{ margin: 0 }}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row gutter={[8, 8]}>
                <Tag>{laborRegime.shortName}</Tag>
                <Tag>{t(`models/job:modal.options.${modal}`)}</Tag>
                <AvailabilityTag job={job} />
              </Row>
            </Col>
          </Row>
        </Link>
      </Box>
    </List.Item>
  );
};

type Props = {
  jobs: Job[];
  page: number;
  total: number;
  onPageChange: (value: number) => void;
  isLoading: boolean;
};

/**
 * @deprecated Use JobListV2 instead: "~/src/components/page/job-list-v2".
 */
export const JobListV1 = ({
  jobs,
  total,
  onPageChange,
  page,
  isLoading,
}: Props) => {
  const { t } = useTranslation("pages/applicant/company");

  return (
    <Box paddingVertical="lg" paddingHorizontal="md">
      <Container width="md" id="jobs">
        <Box paddingBottom="md">
          <Flex justify="center">
            <Typography.Title level={2}>
              {t("pages/applicant/company:job-list.title")}
            </Typography.Title>
          </Flex>
        </Box>
        <List<Job>
          dataSource={jobs}
          locale={{ emptyText: t("pages/applicant/company:job-list.empty") }}
          itemLayout="vertical"
          renderItem={(job) => <ListItem key={job.id} job={job} />}
          loading={isLoading}
          pagination={{
            total: total,
            current: page,
            onChange: onPageChange,
            pageSize: 8,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </Container>
    </Box>
  );
};

import { useCallback, useState } from "react";

/**
 * List parameters state. Like useState but only for merging parameters. Dispatch only accepts objects.
 * @param initialParams Initial list parameters.
 * @returns State and dispatch to merge parameters.
 */
export const useListParams = <T>(initialParams?: T) => {
  const [params, setParams] = useState(initialParams);

  const dispatch = useCallback(
    (params: Partial<T>) => {
      setParams((p) => ({ ...p, ...params }));
    },
    [setParams],
  );

  return [params, dispatch] as const;
};

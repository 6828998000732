import { useTranslation } from "next-i18next";

import stylesheet from "./style.module.css";

import { Page } from "~/src/lib/api/pages";

type Props = {
  page: Page;
};

export const Poster = ({ page }: Props) => {
  const { t } = useTranslation(["pages/applicant/company", "models/page"]);

  if (!page.header) {
    return null;
  }

  return (
    <div style={{ marginBottom: "-32px" }}>
      <img
        src={page.header.url}
        width={page.header.meta.width}
        height={page.header.meta.height}
        alt={t("models.page:header.alt")}
        aria-label={t("pages/applicant/company:poster.title")}
        className={stylesheet.poster}
      />
    </div>
  );
};

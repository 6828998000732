import { Rule } from "antd/lib/form";

/**
 * Format given URL to a human readable form.
 * @param value URL as string.
 * @returns Formatted URL.
 */
export const format = (value: string) => {
  const url = new URL(value);
  return url.hostname;
};

/**
 * Return a Rule object to validate YouTube URLs.
 * @see https://ant.design/components/form/#Rule
 */
export const getYouTubeUrlValidation = (message: string): Rule => ({
  transform: (value) => value.trim(),
  async validator(_, value: string) {
    if (!value) {
      return;
    }
    let url: URL;
    try {
      url = new URL(value);
    } catch {
      throw message;
    }
    if (
      url.host === "www.youtube.com" &&
      url.pathname === "/watch" &&
      url.searchParams.has("v")
    ) {
      return;
    }
    if (url.host === "youtu.be" && url.pathname !== "/") {
      return;
    }
    throw message;
  },
});

import { withLayout } from "@moxy/next-layout";
import { Badge, Button, Descriptions } from "antd";
import { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import { useQuery } from "react-query";

import { Box } from "~/src/components/box";
import { Flex } from "~/src/components/flex";
import { Head } from "~/src/components/head";
import { DescriptionContainer } from "~/src/components/page/descriptions-container";
import { Heading } from "~/src/components/page/heading";
import { JobListV1 } from "~/src/components/page/job-list-v1";
import { MediaGrid } from "~/src/components/page/media-grid";
import { Poster } from "~/src/components/page/poster";
import { TextBlock } from "~/src/components/page/text-block";
import { Layout } from "~/src/layouts/company-page";
import { NotFoundError } from "~/src/lib/api";
import * as Companies from "~/src/lib/api/companies";
import * as Jobs from "~/src/lib/api/jobs";
import * as Pages from "~/src/lib/api/pages";
import { useListParams } from "~/src/lib/use-list-params";
import { useSearchParams } from "~/src/lib/use-search-params";
import { format as formatUrl } from "~/src/lib/validations/url";
import stylesheetUtils from "~/src/styles/utils.module.css";

const i18nNamespaces = [
  "common",
  "components/cropper",
  "pages/applicant/company",
  "models/company",
  "models/job",
];

const Description = ({ company }: { company: Companies.Company }) => {
  const { t } = useTranslation(["pages/applicant/company", "models/company"]);

  return (
    <Box paddingHorizontal="md" paddingBottom="lg">
      <DescriptionContainer>
        <Descriptions size="small" column={{ md: 2, sm: 1, xs: 1 }}>
          <Descriptions.Item
            label={t("pages/applicant/company:fields.name.label")}
          >
            {company.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("models/company:industry.label")}>
            {company.industry.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("models/company:website.label")}>
            <Link href={company.website}>{formatUrl(company.website)}</Link>
          </Descriptions.Item>
          <Descriptions.Item
            label={t("pages/applicant/company:fields.size.label")}
          >
            {t("pages/applicant/company:fields.size.format", {
              value: company.size,
            })}
          </Descriptions.Item>
        </Descriptions>
      </DescriptionContainer>
    </Box>
  );
};

const Page: NextPage = () => {
  const { t } = useTranslation(i18nNamespaces);
  const { pageSlug } = useSearchParams({ pageSlug: "string" });
  const router = useRouter();

  const { data: { data: page } = {} } = useQuery(
    Pages.getQueryKey({ slug: pageSlug }),
    () => Pages.get({ slug: pageSlug }),
    {
      onError(err) {
        if (err instanceof NotFoundError) {
          router.push("/404");
        }
      },
    },
  );

  const company = page?.company;

  const [jobListPaginationParams, mergeJobListPaginationParams] =
    useListParams<Jobs.ListParams>({
      page: 1,
    });

  const jobListParams: Jobs.ListParams = {
    ...jobListPaginationParams,
    company: company?.id,
    status: Jobs.Status.Published,
    internal: false,
    limit: 8,
    sort: ["applicationDeadline+desc"],
  };

  const { data: { data: jobs, total } = {}, isFetching: isLoadingJobs } =
    useQuery(Jobs.getQueryKey(jobListParams), () => Jobs.list(jobListParams), {
      enabled: Boolean(company?.id),
      keepPreviousData: true,
    });

  const handlePagination = (page: number) => {
    mergeJobListPaginationParams({ page });
  };

  if (!page) {
    return null;
  }

  return (
    <>
      <Head
        title={
          company?.name
            ? t("pages/applicant/company:title.ready", {
                company: company.name,
              })
            : t("pages/applicant/company:title.loading")
        }
      >
        <meta
          name="description"
          content={t("pages/applicant/company:meta.description", {
            company: company.name,
          })}
        />
      </Head>

      <Heading companyPage={page} />

      <Poster page={page} />

      <Description company={company} />

      <TextBlock
        id="about"
        title={t("pages/applicant/company:about.title")}
        text={page.description}
        expandable
      />

      <MediaGrid page={page} />

      <JobListV1
        jobs={jobs}
        page={jobListParams.page}
        total={total}
        onPageChange={handlePagination}
        isLoading={isLoadingJobs}
      />
    </>
  );
};

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, i18nNamespaces)),
  },
});

const ExtraHeaderContent = () => {
  const { t } = useTranslation("pages/applicant/company");
  const { pageSlug } = useSearchParams({ pageSlug: "string" });

  const { data: { data: page } = {} } = useQuery(
    Pages.getQueryKey({ slug: pageSlug }),
    () => Pages.get({ slug: pageSlug }),
  );

  const company = page?.company;
  const testimonials = page?.testimonials;

  const jobListParams: Jobs.ListParams = {
    company: company?.id,
    status: Jobs.Status.Published,
    limit: 0,
    applicable: true,
    internal: false,
  };

  const { data: { total: absoluteTotal } = {} } = useQuery(
    Jobs.getQueryKey(jobListParams),
    () => Jobs.list(jobListParams),
    {
      enabled: Boolean(company?.id),
    },
  );

  return (
    <Flex gap="lg">
      <Flex gap="sm" className={stylesheetUtils["hidden-xs"]}>
        <a href="#about">
          <Button type="link">
            {t("pages/applicant/company:navigation.about")}
          </Button>
        </a>
        {testimonials?.length ? (
          <a href="#testimonials">
            <Button type="link">
              {t("pages/applicant/company:navigation.testimonials")}
            </Button>
          </a>
        ) : null}
      </Flex>
      <Badge count={absoluteTotal}>
        <a href="#jobs">
          <Button type="primary">
            {t("pages/applicant/company:navigation.job-page")}
          </Button>
        </a>
      </Badge>
    </Flex>
  );
};

export default withLayout(<Layout extra={<ExtraHeaderContent />} />)(Page);
